.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media print {
	@page {
		size: A4 portrait;
		margin: 10mm;
		color-adjust: exact;
		-webkit-print-color-adjust: exact;
	}

	.page1,
	.page2,
	.page3 {
		page-break-after: always;
	}

	#result-container {
		display: none;
	}

	.page3 {
		transform: scale(0.9);
		margin-top: -60px;
		margin-left: -30px;
		page-break-after: avoid;
	}
}

ol {
	list-style-type: decimal !important;
	list-style-position: inside !important;
	margin-left: 1rem !important;
}
